<template>
  <vue-final-modal v-slot="{ close }" v-bind="$attrs" classes="modal-container" content-class="modal-content"
    :esc-to-close="true"
    :click-to-close="true"
    :styles="{
      width:'80%',
      margin: '0 auto'
    }"
    :content-style="{
      width: 'auto',
      background: '#fff', 
      marginTop: '25px' 
    }"
  >
    <p class="is-flex ">
      <button class="modal__close ml-auto mr-2" @click="close">
        <i class="fas fa-times"></i>
      </button>
    </p>
    <!-- <span class="modal__title"></span> -->
    <div class="modal__content p-2">
      <div class="has-background-white mt-1 cities-modal">
        <cities-grid></cities-grid>
      </div>
    </div>
  </vue-final-modal>
</template>
<script>
import { VueFinalModal } from 'vue-final-modal'
import CitiesGrid from './cities-grid.vue';

export default {
  setup() {
    return {}
  },
  components: {
    VueFinalModal,
    CitiesGrid
  },
}
</script>

<style>
.cities-modal {
  min-width: 800px;
  min-height: 500px;
}
</style>
