export default async function createCity() {
        return {
            name: "Krijo Qytet",
            submit: {
                label: "Krijo",
                action: 'submitCity'
            },
            fields: [
                [
                    {
                        value: '',
                        name: 'name',
                        label: 'Emri',
                        type: 'text-field',
                        style: 'flex-grow: 1',
                        required: true,
                        required_text: 'Kjo fushe eshte e detyrueshme'
                    },
                ],
            ]
        }
    }