export default async function editCity(city){
        return {
            name: "Modifiko Qytetin",
            submit: {
                label: "Modifiko",
                action: 'submitCity'
            },
            fields: [
                [
                    {
                        value: city.name,
                        name: 'name',
                        label: 'Emri',
                        type: 'text-field',
                        style: 'flex-grow: 0.4',
                        required: true,
                        required_text: 'Kjo fushe eshte e detyrueshme'
                    },
                ],
            ]
        }
    }